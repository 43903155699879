<template>
<div class="comps-bar-chart" :id="chartId"></div>
</template>

<script>
// 库存预警 柱状图 当前库存+预警线
import { onMounted, toRefs, reactive, inject, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  props: { chartData: { type: Object } },
  setup(props) {
    const store = useStore();
    let echart = inject("echart");

    const state = reactive({
      chartId: computed(() => store.getters.UUID)
    });

    onMounted(() => {
      initChart();
    })

    // 初始化图表
    const initChart = () => {
      echart.init(document.getElementById(state.chartId)).dispose();
      let barChart = echart.init(document.getElementById(state.chartId));
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          formatter: function () {
            let result = `<div class='chart-tooltip'><span class=''></span>今日库存：${props.chartData.sum_current_storage}</div>`;
            result += `<div>预警线：${props.chartData.int_alarm_inventory}</div>`;
            return result;
          },
          axisPointer: { type: 'cross', label: { backgroundColor: '#6a7985' } }
        },
        color: props.chartData.sum_current_storage > props.chartData.int_alarm_inventory ? '#91cb74' : '#ee6666',
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: ["今日库存"],
        }],
        grid: { left: '3%', right: '10%', bottom: '3%', containLabel: true },
        yAxis: [{
          type: 'value',
          max: function () {
            if (props.chartData.int_alarm_inventory > props.chartData.sum_current_storage) {
              return props.chartData.int_alarm_inventory;
            } else {
              return props.chartData.sum_current_storage;
            }
          }
        }],
        series: [{
          type: 'bar',
          barWidth: '60%',
          data: [props.chartData.sum_current_storage],
          markLine: {
            /*以下设置一行后，平均线就没有开始和结束标记了（即看不见箭头了）*/
            symbol: "none",
            data: [{
              name: "平均线",
              // 支持 'average', 'min', 'max'
              // type: "min",
              yAxis: props.chartData.int_alarm_inventory,
              lineStyle: {
                color: "#e4462b",
                width: 2,
                type: "dashed",
              },
            }]
          }
        }]
      })
    };

    return {
      ...toRefs(state),
      initChart
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
