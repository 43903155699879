<template>
<div class="comps-line-chart" :id="chartId"></div>
</template>

<script>
import { computed, inject, onMounted, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
export default {
  props: { chartData: { type: Array }, dateRange: { type: Array }, dateType: { type: String } },
  setup(props) {
    const store = useStore();
    let echart = inject("echart");
    // 引入moment.js
    let moment = require("moment");

    const state = reactive({
      dateRange: computed(() => props.dateRange),
      dateType: computed(() => props.dateType),
      chartId: computed(() => store.getters.UUID)
    })

    onMounted(() => {
      initChart(props.chartData);
    })

    // 计算横坐标
    const computedXaxis = () => {
      let start = new Date(state.dateRange[0]).getTime(),
        end = new Date(state.dateRange[1]).getTime(),
        temp = start,
        result = [];
      if (state.dateType != "y") {
        result.push(state.dateRange[0]);
        while (temp < end) {
          temp = temp + 24 * 3600 * 1000;
          let temp_1 = new Date(temp),
            year = temp_1.getFullYear(),
            month = temp_1.getMonth() + 1,
            day = temp_1.getDate(),
            target = year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
          result.push(target);
        }
        return result;
      } else {
        start = state.dateRange[0];
        result.push(start.slice(0, 7));
        // 得到目标年的所有月份
        for (let i = 1; i <= 12; i++) {
          result.push(moment(start).add(i, "months").format('YYYY-MM'));
        }
        return result;
      }
    };

    // 计算图表配置项
    const computedSeries = (chartData) => {
      let xAxis = computedXaxis(),
        result = [];
      for (let i = 0; i < xAxis.length; i++) {
        // 循环数据，对于没有数据的时间，赋值0
        let itemKey = xAxis[i],
          index = chartData.findIndex(t => t.txt_date == itemKey);

        if (index != -1) {
          result.push({ date: itemKey, value: parseInt(chartData[index].sum_storage_out || 0) });
        } else {
          result.push({ date: itemKey, value: 0 });
        }
      }
      return result;
    }

    // 初始化图表
    const initChart = (chartData) => {
      let seriesData = computedSeries(chartData);
      echart.init(document.getElementById(state.chartId)).dispose();
      let lineChart = echart.init(document.getElementById(state.chartId));
      lineChart.setOption({
        tooltip: {
          trigger: 'axis',
          formatter: "{b}<br />出库数量：{c}",
          axisPointer: { type: 'cross', label: { backgroundColor: '#6a7985' } }
        },
        legend: { show: false },
        grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: computedXaxis()
        }],
        yAxis: [{ type: 'value', minInterval: 1, axisLine: { show: true }, }],
        series: [{
            data: seriesData.map(t => t.value),
            // data: [10, 5, 4, 6, 9, 13, 18],
            emphasis: { focus: 'series' },
            type: 'line',
            smooth: true,
            itemStyle: {
              color: "#83bff6"
            }
          },
          // {
          //   type: "bar",
          //   // data: seriesData.map(t => t.value),
          //   data: [10, 5, 4, 6, 9, 13, 18],
          //   barWidth: '10%',
          //   itemStyle: {
          //     color: echart.graphic.LinearGradient(0, 0, 0, 1, [
          //       { offset: 0, color: '#83bff6' },
          //       { offset: 0.5, color: '#188df0' },
          //       { offset: 1, color: '#188df0' }
          //     ])
          //   },
          // }
        ]
      });
    };

    return {
      initChart,
      ...toRefs(state),
      computedXaxis,
      computedSeries
    }
  }
}
</script>
