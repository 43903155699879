<template>
<div class="inventory-manange-index" id="page">
  <date-picker ref="datePickerRef" @dateChange="(e) => {changeDate(e)}" />
  <div class="imi-chart-part" v-loading="loading">
    <div class="imicp-item" v-for="(item, index) in chartData" :key="index">
      <div class="imicpi-title">{{item.name}}</div>
      <div class="imicpi-chart-line">
        <line-chart :chartData="item.lines" :dateRange="dateRange" :dateType="dateType" v-if="lineShow" />
        <bar-chart v-if="lineShow" :chartData="item.bar" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
// 库存预警
import { onMounted, reactive, ref, toRefs } from 'vue';
import DatePicker from "./comps/datePicker.vue";
import LineChart from "./comps/line.vue";
import BarChart from "./comps/bar.vue";
import { getSituationEqAndCo } from "api/apis.js";
import { ElMessage } from 'element-plus';
export default {
  components: { DatePicker, LineChart, BarChart },
  setup() {
    const datePickerRef = ref(null);
    const state = reactive({
      loading: false,
      chartData: [],
      dateType: "",
      dateRange: [],
      lineShow: false
    })

    onMounted(() => {
      getData();
      state.dateRange = datePickerRef.value.dateRange;
      state.dateType = "w";
    });

    const changeDate = (e) => {
      state.dateType = e.substring(1, 0);
      state.dateRange = datePickerRef.value.dateRange;
      getData();
    }

    // 获取数据
    const getData = () => {
      state.loading = true;
      state.lineShow = false;
      getSituationEqAndCo({
        dt_from: datePickerRef.value.dateRange[0],
        dt_to: datePickerRef.value.dateRange[1],
      }).then(response => {
        if (response.code == 200) {
          formatData(response.data);
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    };

    // 格式化数据
    const formatData = (data) => {
      let keys = Object.keys(data);
      state.chartData = [];
      for (let i = 0; i < keys.length; i++) {
        let lines = data[keys[i]],
          name = keys[i],
          bar = data[keys[i]][0];
        state.chartData.push({ lines, bar, name });
      }
      state.lineShow = true;
    };

    return {
      ...toRefs(state),
      datePickerRef,
      getData,
      formatData,
      changeDate
    }
  }
}
</script>

<style lang="scss" scoped>
.inventory-manange-index {
  .imi-chart-part {
    margin-top: 15px;
    height: calc(100% - 50px);
    overflow: auto;

    // 滚动条
    &::-webkit-scrollbar {
      display: block;
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgba(14, 22, 37, 0.5);
    }

    .imicp-item {
      height: 350px;
      margin-bottom: 6px;
      padding-bottom: 4px;
      border-bottom: 1px solid #aeaeae;

      .imicpi-title {
        font-weight: bold;
        margin-bottom: 4px;
      }

      .imicpi-chart-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: calc(100% - 25px);
        width: 100%;

        .comps-line-chart {
          height: 100%;
          width: 68%;
          margin-right: 6px;
        }

        .comps-bar-chart {
          height: 100%;
          width: 30%;
        }
      }
    }
  }
}
</style>
